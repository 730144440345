import './HomePage.scss'
import { AnimatedNumber } from '../../Components/Text/AnimatedNumber/AnimatedNumber';
import VideoTitle from '../../Components/VideoComponents/VideoTitle/VideoTitle';
import "../../i18n";
import { FaAngleRight } from 'react-icons/fa';
import { useTranslation } from "react-i18next";
import MovingDotsBackground from '../../Components/Background/AnimatedDotBackground';
import { HAngledBackgroundTextSection } from '../../Components/Text/AngledBackgroundTextSection/AngledBackgroundTextSection';
import MyButton from '../../Components/Buttons/SimpleButton/Button';
import { useNavigate } from 'react-router-dom';
import useDetectView from '../../Components/Hook/useDetectView';
import { View } from '../../Data/Constants';

export default function Home() {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const currentView = useDetectView();

    const handleClickfunc = async () => {
      await /* API call here */
      navigate('/projets');
    }

    return (
      <div>
        <div>
          <VideoTitle title={t("home.title.title")} subtitle={t("home.title.subtitle")}/>
        </div>
        <div>
          <div className='page-section'>
            <div className='text-container-lg' style={{marginTop: "5rem"}}>
              <h1>{t("home.description.title")}</h1>
              <div className='justify-text'>
                <div>
                  {t("home.description.firstText")}
                </div>
                <div>
                  {t("home.description.secondText")}
                </div>
              </div>
              <div style={{textAlign:"center"}}>
                <div>
                  { currentView!==View.Mobile ?
                  <div className='homepage-btn' onClick={handleClickfunc}>
                    <span>{t("home.button.text")}</span>
                    <span className='homepage-btn-arrow'><FaAngleRight/></span>
                  </div>
                  :
                  <MyButton text={t("home.button.text")} OnClick={handleClickfunc}/>
                  }
                </div>
              </div>
            </div>
          </div>
          <div>
            {
              //<HAngledBackgroundTextSection>
            }
              <div className="stats-section">
                  <h1 style={{textAlign:"center", marginBottom:"5rem", fontWeight:"bold"}}>{t("home.stats.title.title")}</h1>
                  <div className='circles-container'>
                    <div className="circle-wrapper">
                      <AnimatedNumber num={446} delayInSecond={1} isDark={true}  speed={1.5} durationInSecond={1} circle={true} text={t("home.stats.circles.first.text")} enableShadow={true} fontSize={35} height={250} width={250} animationDelay={1000} numTension={10}></AnimatedNumber>
                    </div>
                    <div className="circle-wrapper">
                      <AnimatedNumber num={723695} delayInSecond={2}  speed={3} isDark={true} durationInSecond={1} circle={true} side_text={t("home.stats.circles.second.side-text")} text={t("home.stats.circles.second.text")} enableShadow={true} fontSize={35} height={250} width={250} animationDelay={2000} numTension={10}></AnimatedNumber>
                    </div>
                    <div className="circle-wrapper">
                      <AnimatedNumber num={519450} delayInSecond={3}  speed={2.8} isDark={true} durationInSecond={1} circle={true} side_text={t("home.stats.circles.third.side-text")} text={t("home.stats.circles.third.text")} enableShadow={true} fontSize={35} height={250} width={250} animationDelay={3000} numTension={10}></AnimatedNumber>
                    </div>
                    <div className="circle-wrapper">
                      <AnimatedNumber num={15} delayInSecond={4} speed={1.3} isDark={true} durationInSecond={1} circle={true} side_text={t("home.stats.circles.fourth.side-text")} text={t("home.stats.circles.fourth.text")} enableShadow={true} fontSize={35} height={250} width={250} animationDelay={4000} numTension={10}></AnimatedNumber>
                  </div>
                </div>
              </div>
            {
            //</HAngledBackgroundTextSection>
            }

          </div>
          <div className="page-section ">
           
          </div>
        </div>
      </div>
    );
  }


